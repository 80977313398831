.passenger_list {
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;

  .empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .waiting {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    &.passenger {
      background-color: rgba(255, 255, 255, .75);
      padding: 16px;
    }

    .warning {
      margin-top: 8px;
      padding: 8px;
      color: #FFF;
      border-radius: 4px;
      background-color: rgba(255,152,0,.5);
    }
  }
}

.refresh_passengers {
  cursor: pointer;
  margin-right: -6px;
}