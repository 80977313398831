.rounded_input {
  margin-left: -16px;
  position: relative;
  padding: 7px 40px 7px 22px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 1rem;
  border-radius: 0 12px 12px 0;
  background-color: #3a4044;
  border-width: 0;
  color: #FFF;
  width: 228px;

  &:active {
    border-color: red;
  }

  &.error {
    background-color: #fcc2c2;
    color: #640202;
    border-color: #640202;

    &:hover {
      border-color: #640202;
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 0 12px 12px 0;
    background-color: #3a4044;
    width: 100%;

    input {
      color: #FFF;
      padding: 7px 40px 7px 22px;
      width: 100%;
    }
  }
}

.error_icon {
  position: absolute;
  margin-left: 4px;
  top: 50%;
  transform: translateY(-50%);
  color: #fcc2c2;
  right: -12px;
}