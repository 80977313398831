@import "../../Common/theme_variables";

.container {
    background: $header-bg-color;
    position: relative;
    padding-bottom: 8px;
    display: flex;
    flex-shrink: 1;
    flex-direction: column;

    .centered {
        display: flex;
        align-content: center;
    }

    .invite_button {
        color: #FFF;
        margin-left: 8px;
        margin-right: 8px;
        background-color: $icon-bg-color;

        &:hover {
            background-color:$icon-bg-color-hover;
        }
    }
}