@import "../theme_variables";

.full_screen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #555;
    z-index: 9999;
    opacity: .7;
    color: $text-color;

    .spinner_block {
        position: relative;
        z-index: 10000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 1.3;

        .spinner {
            color: $spinner-color;
        }

        .message {
            margin-top: 8px;
            color: $spinner-text-color;
        }
    }
}