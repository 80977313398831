@import "../../Common/theme_variables";

.game_content {
  position: relative;
  flex-grow: 1;
  overflow-y: scroll !important;
}

.center_content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.center_content_absolute {
  width: 60%;
  position: absolute;
  top: 32%;
  left: 20%;
  z-index: 999;

  &.hidden {
    display: none;
  }

  #turn-reference {
    --animate-duration: 3s;
  }
}

.places_empty {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    display: inline-block;
    padding: 0 6px;
    background-color: #FFF;
  }
}

.progress_bar {
  text-align: center;
}

.clickable {
  font-size: 24px;
  cursor: pointer;
  color: #FFF;
}

.clickable_link {
  font-weight: normal;
  text-decoration: underline;
  margin-right: 6px;
  cursor: pointer;
}

.gif {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;
}