.textfield_container {
    display: flex;
    flex-direction: row;
    height: 35px;
    padding: 8px;
    margin-bottom: -8px;
    align-items: center;
    position: relative;
    width: 325px;

    .icon {
        position: relative;
        z-index: 999;
    }

    .error_icon {
        position: absolute;
        margin-left: 4px;
        top: 50%;
        transform: translateY(-50%);
        color: #fcc2c2;
        right: -4px;
    }

    .full_width {
        width: 100%;
    }

    div.rounded_input {
        margin-left: -16px;
        position: relative;
        width: 100%;

        &.error {
            .MuiOutlinedInput-root {
                background-color: #fcc2c2;

                input {
                    color: #640202;
                }

                & fieldset {
                    border-color: #640202;
                }

                & :hover fieldset {
                    border-color: #640202;
                }
            }
        }

        .MuiOutlinedInput-root {
            border-radius: 0 12px 12px 0;
            background-color: #3a4044;
            width: 100%;

            input {
                color: #FFF;
                padding: 7px 40px 7px 22px;
                width: 100%;
            }
        }
    }

    .disabled {
        color: #FFF;
        padding-left: 8px;
    }
}