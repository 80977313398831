@import "theme_variables";

.map_bg {
    position: relative;
    z-index: 1;
    max-width: 767px;
    margin: 0 auto;
    height: 100%;
}

.map_bg::before {
    position: absolute;
    top: -16px;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .1;
    content: "";
    z-index: -1;
    background: center no-repeat url("../../assets/metro_768.png");
}

.map_bg.about::before {
    opacity: .05;
}

.main_content {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.game_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.game_content {
    flex-grow: 1;
    overflow-y: scroll;
}

.block_header {
    background-color: $row-header-bg-color;
    font-weight: bold;
    font-size: 16px;
    height: 40px;
    color: #FFF;
    padding: 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    position: relative;

    &.block_header_darker {
        background-color: $row-header-bg-color-darker;
    }

    .block_section {
        display: flex;
        flex-direction: row;
        align-items: center;

        .train_icon_block svg {
            color: #FFF;
        }
    }
}

.header_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;

    .header_section {
        display: flex;
        align-items: center;
    }
}

.username_container {
    flex-grow: 1;
    background-color: $header-bg-color;
    padding-bottom: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.action_button_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    gap: 64px;

    &.dark {
        background-color: $header-bg-color;
    }
}

.alert_error {
    position: absolute;
    width: 50%;
    bottom: 64px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    height: 32px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.train_icon_block {
    display: flex;
    align-items: center;
    margin-left: 8px;
    color: #FFF;
    padding: 6px 0;

    &.train_block_small {
        font-size: 12px;
    }

    &.train_block_medium {
        font-size: 20px;
    }

    &.train_block_large {
        font-size: 24px;
    }

    &.train_block_bold {
        font-weight: bold;
    }

    &.train_block_no_bg {
        .train_text {
            background: none;
            border: none;

            span.text_marker_left, span.text_marker, span.text_marker_right {
                display: none;
            }
        }

        div {
            border: none !important;
            padding: 0 !important;
        }
    }

    .train_icon {
        margin-right: 2px;
        color: $header-text-color;
    }

    .train_text {
        display: flex;
        flex-direction: row;
        color: $text-color;
        background: linear-gradient(to bottom, $text-bg-color-top 50%, $text-bg-color-bottom 50%);

        div {
            border: 2px solid $text-border-color;
            border-left: none;
            padding: 0 4px;
        }

        div:first-child {
            border-left: 2px solid $text-border-color;
        }
    }
}

.help_button {
    position: absolute;
    top: 8px;
    right: 8px;

    font-size: 1.35em;
    background-color: $help-icon-bg-color !important;

    &:hover {
        cursor: pointer;
        background-color: $help-icon-bg-color-hover !important;
    }

    &.normal {
        position: inherit;
    }
}

.app_store_icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 16px;

    a {
        text-align: center;

        .store_icon {
            height: 40px;
            padding-left: 8px;
            padding-right: 8px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.powered_by {
    text-align: center;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 48px;
        padding-left: 6px;
        vertical-align: middle;
        margin-top: -4px;
    }

    span.demo {
        margin-left: 16px;

        &:hover {
            cursor: pointer;
        }
    }
}

.hidden {
    display: none;
}

@media (max-width: 360px) {
    .train_text {
        font-size: .7em;
    }
}