.game_row_border {
  height: 8px;
  background-color: #EEEEEE;
}

.game_row {
  display: flex;
  height: 78px;
  color: #424242;
  align-items: center;

  .place_icon {
    width: 48px;
    min-width: 48px;
    margin: 0 12px;

    &:hover {
      cursor: pointer;
    }
  }

  .place_overview {
    margin-right: 8px;
    flex-grow: 3;
    display: flex;
    flex-direction: column;
    min-width: 0;

    .place_name {
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 3px;

      &:hover {
        cursor: pointer;
      }

      .place_url {
        display: inline-block;
        margin-left: 6px;
      }
    }

    .place_rating {
      width: 72px;
      padding: 2px 0;
    }

    .place_category {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }

    .place_location {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }
  }

  .place_additional {
    flex-grow: 1;
    align-self: flex-start;
    margin-top: 8px;

    .place_distance {
      padding: 4px 6px;
      background: #EEE;
      font-size: 12px;
      border-radius: 16px;
      display: inline-block;
      float: right;
      min-width: 40px;
      max-width: 54px;
      text-align: center;
    }

    .place_cost {
      clear: both;
      margin-top: 4px;
      text-align: right;
      min-width: 48px;
    }
  }

  .divider {
    width: 2px;
    background-color: #9E9E9E;
    height: 85%;
    margin: 0 0 0 16px;
  }

  .action_button {
    font-size: 48px;
    min-width: 72px;
    max-width: 72px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &.disabled {
      cursor: default;
      opacity: .5;

      &:hover {
        cursor: default;
      }
    }

    .x_mark {
      position: absolute;
      font-size: 24px;
      top: -12%;
      right: 16%;
      color: #B71C1C;
    }

    .bringbacks {
      position: absolute;
      bottom: 12%;
      right: 24%;
    }

    &.check {
      color: #0D47A1;
      cursor: default;
    }

    &.circle-minus {
      color: #B71C1C;
    }

    &.circle-plus {
      color: #1B5E20;
    }

    &.question {
      color: #424242;
    }

    &.thumbs-up {
      color: #1B5E20;
    }
  }
}