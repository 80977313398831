@import "../theme_variables";

h2 {
  background-color: $icon-bg-color;
  color: #FFF;
}

.list {
  list-style: none;

  li {
    position: relative;
    padding: 8px;

    .current_player {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -24px;
      width: 24px;
      height: 24px;
    }
  }
}