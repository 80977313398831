@import "../Common/theme_variables";

.container {
    background: $header-bg-color;
    position: relative;
    padding-bottom: 8px;
    flex-shrink: 1;

    .header_row {
        display: flex;
        align-items: center;
        position: relative;

        :global(.textfield_container) {
            width: 65%;
        }

        .location {
            position: relative;

            .location_dropdown {
                color: #FFF;
                width: 18px;
                height: 18px;
                position: absolute;
                z-index: 999;
                right: 40px;

                &:hover {
                    cursor: pointer;
                    color: #FB8C00;
                }
            }

            .location_progress {
                color: #FFF;
                position: absolute;
                z-index: 999;
                right: 40px;
            }
        }

        .search_button {
            margin-left: -16px;
            margin-top: 8px;
        }

        button.filter_button {
            margin: 0 8px;
            background-color: $icon-bg-color;

            &:hover {
                background-color: $icon-bg-color-hover;
            }
        }

        .filter_button_icon {
            height: 26px;
            font-size: 26px;
        }
    }
}

.location_menu {
    ul {
        padding: 0;
        margin: 0;

        li {
            min-height: 32px;
            max-height: 32px;
        }
    }

    .location_icon {
        margin-left: 12px;
    }
}