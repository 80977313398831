.passenger_row {
  width: 86%;
  border-radius: 8px;
  margin: 8px auto;
  position: relative;
}

.action_button {
  font-size: 40px;
  min-width: 48px;
  max-width: 48px;
  cursor: pointer;
  color: #B71C1C;
  position: absolute;
  top: 50%;
  right: -14px;
  transform: translateY(-50%);
}