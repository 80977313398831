@import "../theme_variables.scss";

.icon {
    display: inline-block;
    z-index: 999;
}

.icon_bg {
    background-color: $icon-bg-color;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;

    &.normal {
        width: 35px;
        height: 35px;
    }

    &.large {
        width: 48px;
        height: 48px;
        font-size: 1.5em;
    }

    &.extra_large {
        width: 64px;
        height: 64px;
        font-size: 2em;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    &.clickable {
        &:hover {
            cursor: pointer;
            background-color: $icon-bg-color-hover;
        }

        &.disabled {
            cursor: default;
            background-color: $action-disabled;

            &:hover {
                cursor: default;
                background-color: $action-disabled;
            }

            svg {
                opacity: .3;
            }
        }
    }
}

.icon_bg.border {
    border: 2px solid $icon-bg-color-hover;
}

.icon_img {
    color: $text-color;
}