._bringback_animation {
    animation-name: bounceInLeft, flash;
    -webkit-animation-name: bounceInLeft, flash;

    animation-duration: 2s;
    -webkit-animation-duration: 2s;
}

._remove_animation {
    animation-name: bounceOutLeft, flash;
    -webkit-animation-name: bounceOutLeft, flash;

    animation-duration: 2s;
    -webkit-animation-duration: 2s;
}

._flash_animation {
    animation-name: flash;
    -webkit-animation-name: flash;

    animation-duration: 4s;
    -webkit-animation-duration: 4s;
}