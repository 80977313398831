$action-disabled: rgba(238, 238, 238, 0.3);
$background-color: #7a7a7a;
$button-disabled: #f5f5f5;
$button-primary: #FFA726;
$button-secondary: #99cc33;
$header-bg-color: #122128;
$header-page-text-shadow: #474b50;
$header-text-color: #fdd51b;
$help-icon-bg-color: #42A5F5;
$help-icon-bg-color-hover: #1976D2;
$icon-bg-color: #FFA726;
$icon-bg-color-hover: #FB8C00;
$row-header-bg-color: #FFA726;
$row-header-bg-color-dark: darken($row-header-bg-color, 30%);
$row-header-bg-color-darker: #e78c05;
$spinner-color: #FB8C00;
$spinner-text-color: #FFA726;
$text-bg-color-top: #182931;
$text-bg-color-bottom: #363636;
$text-border-color: #39464d;
$text-color: #FFF;
$ticket-offline: #960000;