@import "../../components/Common/theme_variables";

.content {
  padding: 16px;
  margin-top: 0;
}

.main_description {
  padding: 8px;
}

.description {
  margin-bottom: 8px;
}

.avatar {
  display: inline-block;
  width: 72px;
  height: 72px;
}

.user_description, .faq {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  padding: 8px;
  background-color: lighten($icon-bg-color, 15%);
  border-radius: 8px;
  color: darken($icon-bg-color-hover, 30%);
  width: 90%;
}

.faq {
  background-color: lighten($help-icon-bg-color, 15%);

  & :hover {
    cursor: pointer;
  }
}

.llc {
  margin-left: 4px;
  font-size: 12px;
}

.accordion {
  width: 100%;
  margin-bottom: 16px;

  .question {
    background-color: $icon-bg-color-hover;
    width: 100%;

    p {
      color: #FFF;
    }
  }

  .link {
    & :hover {
      cursor: pointer;
    }
  }
}

.actions {
  display: flex;
  justify-content: space-around;
  padding-bottom: 16px;

  &.tutorial_actions {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
  }

  .action {
    .action_button {
      &:hover {
        cursor: pointer;
        background-color: $icon-bg-color-hover;
      }
    }
  }

  .action_tutorial {
    width: 64px;
    height: 64px;
    background: center no-repeat url("../../assets/tutorial_icon_normal.png");
    cursor: pointer;

    &:hover {
      background: center no-repeat url("../../assets/tutorial_icon_hover.png");
    }
  }
}

.tutorial {
  padding: 0 16px 16px 16px;

  .image {
    display: flex;
    justify-content: center;

    img {
      margin-top: 16px;
      width: 72%;
    }
  }
}

.desktop {
  .image {
    img {
      width: 60%;
    }
  }
}