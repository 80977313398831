@import "../../Common/theme_variables";

.avatars {
  opacity: 1.0;
  color: #FFF;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10%;

  .avatar {
    position: relative;

    /* big ups to https://codepen.io/peeke/pen/BjxXZa for the pulsating circle */
    .highlight {
      position: absolute;
      left: 75%;
      transform: translateX(-75%);
      width: 16px;
      height: 16px;
      z-index: 999;

      &:before {
        content: '';
        position: relative;
        display: block;
        width: 300%;
        height: 300%;
        box-sizing: border-box;
        margin-left: -100%;
        margin-top: -100%;
        border-radius: 45px;
        background-color: $help-icon-bg-color;
        animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 15px;
        box-shadow: 0 0 8px rgba(0,0,0,.3);
        animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
      }
    }

    @keyframes pulse-ring {
      0% {
        transform: scale(.33);
      }
      80%, 100% {
        opacity: 0;
      }
    }

    @keyframes pulse-dot {
      0% {
        transform: scale(.8);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(.8);
      }
    }

    img {
      display: inline-block;
      width: 48px;
      height: 48px;
      margin: 8px;

      &.offline {
        opacity: .3;
      }
    }

    .name_block {
      position: absolute;
      bottom: 16px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;

      .name {
        font-weight: bold;
        color: #FFF;

        &.offline {
          color: #FFF;
          opacity: .4;
        }
      }
    }
  }
}