@import "../../Common/theme_variables";

.container {
    background: $header-bg-color;
    position: relative;
    padding-bottom: 8px;
    flex-shrink: 1;

    .pad_right {
        margin-right: 8px;
    }
}