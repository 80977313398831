@import "../../Common/theme_variables";

.game_winner {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .winner_img {
    position: relative;
    top: 0;

    &:hover {
      cursor: pointer;
    }

    img {
      width: 100%;
    }
  }

  .winner_text {
    font-weight: bold;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
    color: #424242;
    font-size: 24px;
  }

  .game_row {
    display: flex;
    width: 80%;
    height: 78px;
    color: #424242;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 24px;

    .place_icon {
      width: 48px;
      min-width: 48px;
      margin: 0 12px;

      &:hover {
        cursor: pointer;
      }
    }

    .place_overview {
      margin-right: 12px;
      flex-grow: 3;
      display: flex;
      flex-direction: column;
      min-width: 0;

      .place_name {
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 3px;

        &:hover {
          cursor: pointer;
        }

        .place_url {
          display: inline-block;
          margin-left: 6px;
        }
      }

    .place_rating {
      width: 72px;
      padding: 2px 0;
    }

    .place_category {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }

    .place_location {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }
  }

    .divider {
      width: 2px;
      background-color: #9E9E9E;
      height: 85%;
      margin: 0 0 0 16px;
    }

    .action_button {
      color: #1b5e20;
      cursor: pointer;
      font-size: 64px;
      max-width: 64px;
      min-width: 64px;
      position: relative;
      text-align: center;
      margin: 0 8px;
    }
  }

  .meeting_time {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    opacity: .9;
    margin-top: 16px;
    color: #424242;
    position: relative;
  }

  .actions {
    display: flex;
    justify-content: space-around;
    padding-top: 32px;
    padding-bottom: 12px;

    .action {
      .action_button {
        &:hover {
          cursor: pointer;
          background-color: $icon-bg-color-hover;
        }
      }
    }
  }
}