@import "../../components/Common/theme_variables";

.background {
  position: absolute;
  background-color: $icon-bg-color;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: .4;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;

  .version {
    margin-top: 4px;
    font-size: 10px;
    align-self: end;
  }

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;

    .the_lunch_train {
      width: 306px;

      @media (min-width: 414px) {
        width: 396px;
      }

      @media (min-width: 550px) {
        width: 512px;
      }
    }

    .flex_break {
      flex-basis: 100%;
      height: 0;
    }
  }

  .buttons {
    .actions {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;

      .button_block {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 24px 24px;
        position: relative;

        &.color_white {
          color: #FFF;
        }

        img {
          width: 108px;
          height: 108px;
        }

        .about_button, .info_button {
          width: 48px;
          height: 48px;
        }

        &:hover {
          cursor: pointer;
        }

        button.user_button {
          margin-top: 8px;
          width: 128px;
        }

        button.help_button {
          margin-top: 8px;
          width: inherit;
        }

        .block_yelp {
          position: absolute;
          font-weight: bold;
          color: #FFF;
          background-color: #000;
          text-align: center;
          padding: 6px;
          transform: rotate(-30deg);
          z-index: 9999;
          width: 120%;
        }
      }
    }
  }
}

.demo {
  padding-left: 4px;
}