@import "../theme_variables";

.ticket_container {
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  position: relative;
  color: $header-bg-color;
  filter: drop-shadow(5px 5px 8px rgba(0, 0, 0, 0.4)) blur(0.2px);

  .status {
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    font-size: 2em;
    color: $ticket-offline;
    opacity: .6;
  }

  &.offline {
    opacity: .5;
  }

  .icon_bg {
    width: 128px;
    height: 128px;
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(0%, -50%);
    opacity: .1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 64px;

    &.conductor {
      background-color: $button-secondary;
    }

    &.passenger {
      background-color: $button-primary;
    }

    img {
      width: 84px;
    }
  }

  .left_side_perf {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    left: -6px;
    z-index: -999;
    top: 50%;
    transform: translateY(-50%);

    li {
      height: 22px;
      width: 24px;
      transform: rotate(-90deg);
      font-size: 12px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: radial-gradient(circle at 13px 0, rgba(255, 255, 255, 0) 0.3em, #FFF 0.4em);
    }
  }

  .right_side_perf {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    right: -6px;
    z-index: -999;
    top: 50%;
    transform: translateY(-50%);

    li {
      height: 22px;
      width: 24px;
      transform: rotate(90deg);
      font-size: 12px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: radial-gradient(circle at 13px 0, rgba(255, 255, 255, 0) 0.3em, #FFF 0.4em);
    }
  }

  .ticket_image {
    height: 100%;
    overflow: hidden;

    img {
      background-size: 100%;
      width: 235px;
      position: absolute;
      top: 5%;
      right: 22%;
      opacity: .15;
    }
  }

  .ticket_top {
    height: 20px;
    display: flex;
    background-color: $header-text-color;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;

    &.conductor {
      background-color: $button-secondary;
    }

    .game_id {
      font-weight: bold;
    }

    .boarding_pass {
      color: #FFF;
    }
  }

  .ticket_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 6px 8px 6px 0;
    opacity: .7;

    .user {
      display: flex;
      flex-direction: row;
      align-items: center;

      .user_icon {
        font-size: 24px;
        padding: 6px;
      }

      .name {
        font-size: 24px;
        font-weight: bold;
      }
    }

    .user_small {
      .user_icon {
        display: none;
      }

      .name {
        font-size: 14px;
      }
    }
  }

  .ticket_content_2 {
    background-color: #EFEFEF;
    color: $header-bg-color;
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;

    .section_header {
      font-weight: bold;
      color: #BBB;
    }
  }

  .perforation {
    height: 98%;
    border: 1px dashed #777;
    position: absolute;
    right: 30%;
  }

  .ticket_bottom {
    height: 20px;
    background-color: $header-text-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    color: $header-bg-color;

    &.conductor {
      background-color: $button-secondary;

      .barcode {
        height: 20px;
        width: 68px;
        background: $header-bg-color;
        box-shadow:
                inset 0 1px 0 $button-secondary,
                inset -2px 0 0 $button-secondary,
                inset -4px 0 0 $header-bg-color,
                inset -7px 0 0 $button-secondary,
                inset -9px 0 0 $header-bg-color,
                inset -11px 0 0 $button-secondary,
                inset -12px 0 0 $header-bg-color,
                inset -13px 0 0 $button-secondary,
                inset -15px 0 0 $header-bg-color,
                inset -18px 0 0 $button-secondary,
                inset -20px 0 0 $header-bg-color,
                inset -23px 0 0 $button-secondary,
                inset -25px 0 0 $header-bg-color,
                inset -20px 0 0 $button-secondary,
                inset -23px 0 0 $header-bg-color,
                inset -25px 0 0 $button-secondary,
                inset -26px 0 0 $header-bg-color,
                inset -26px 0 0 $button-secondary,
                inset -27px 0 0 $header-bg-color,
                inset -30px 0 0 $button-secondary,
                inset -31px 0 0 $header-bg-color,
                inset -33px 0 0 $button-secondary,
                inset -35px 0 0 $header-bg-color,
                inset -37px 0 0 $button-secondary,
                inset -40px 0 0 $header-bg-color,
                inset -43px 0 0 $button-secondary,
                inset -44px 0 0 $header-bg-color,
                inset -45px 0 0 $button-secondary,
                inset -46px 0 0 $header-bg-color,
                inset -48px 0 0 $button-secondary,
                inset -49px 0 0 $header-bg-color,
                inset -50px 0 0 $button-secondary,
                inset -52px 0 0 $header-bg-color,
                inset -54px 0 0 $button-secondary,
                inset -55px 0 0 $header-bg-color,
                inset -57px 0 0 $button-secondary,
                inset -59px 0 0 $header-bg-color,
                inset -61px 0 0 $button-secondary,
                inset -64px 0 0 $header-bg-color,
                inset -63px 0 0 $button-secondary,
                inset -64px 0 0 $header-bg-color,
                inset -65px 0 0 $button-secondary,
                inset -66px 0 0 $header-bg-color,
                inset -67px 0 0 $button-secondary,
                inset -68px 0 0 $header-bg-color,
      }
    }

    .barcode {
      height: 20px;
      width: 68px;
      background: $header-bg-color;
      box-shadow:
      inset 0 1px 0 $header-text-color,
      inset -2px 0 0 $header-text-color,
      inset -4px 0 0 $header-bg-color,
      inset -7px 0 0 $header-text-color,
      inset -9px 0 0 $header-bg-color,
      inset -11px 0 0 $header-text-color,
      inset -12px 0 0 $header-bg-color,
      inset -13px 0 0 $header-text-color,
      inset -15px 0 0 $header-bg-color,
      inset -18px 0 0 $header-text-color,
      inset -20px 0 0 $header-bg-color,
      inset -23px 0 0 $header-text-color,
      inset -25px 0 0 $header-bg-color,
      inset -20px 0 0 $header-text-color,
      inset -23px 0 0 $header-bg-color,
      inset -25px 0 0 $header-text-color,
      inset -26px 0 0 $header-bg-color,
      inset -26px 0 0 $header-text-color,
      inset -27px 0 0 $header-bg-color,
      inset -30px 0 0 $header-text-color,
      inset -31px 0 0 $header-bg-color,
      inset -33px 0 0 $header-text-color,
      inset -35px 0 0 $header-bg-color,
      inset -37px 0 0 $header-text-color,
      inset -40px 0 0 $header-bg-color,
      inset -43px 0 0 $header-text-color,
      inset -44px 0 0 $header-bg-color,
      inset -45px 0 0 $header-text-color,
      inset -46px 0 0 $header-bg-color,
      inset -48px 0 0 $header-text-color,
      inset -49px 0 0 $header-bg-color,
      inset -50px 0 0 $header-text-color,
      inset -52px 0 0 $header-bg-color,
      inset -54px 0 0 $header-text-color,
      inset -55px 0 0 $header-bg-color,
      inset -57px 0 0 $header-text-color,
      inset -59px 0 0 $header-bg-color,
      inset -61px 0 0 $header-text-color,
      inset -64px 0 0 $header-bg-color,
      inset -63px 0 0 $header-text-color,
      inset -64px 0 0 $header-bg-color,
      inset -65px 0 0 $header-text-color,
      inset -66px 0 0 $header-bg-color,
      inset -67px 0 0 $header-text-color,
      inset -68px 0 0 $header-bg-color,
    }
  }
}